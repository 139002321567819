import { useState, useEffect } from 'react';
import { Alert, Button, Toast } from 'react-bootstrap';

const VERSION_CHECK_INTERVAL = 5 * 60 * 1000;
const VERSION_KEY = 'app_version';

export default function VersionChecker() {
    const [newVersionAvailable, setNewVersionAvailable] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const checkVersion = async () => {
            try {
                const versionUrl = process.env.NODE_ENV === 'development' 
                    ? '/version.json' 
                    : `${process.env.PUBLIC_URL}/version.json`;
                
                const response = await fetch(`${versionUrl}?t=${Date.now()}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const { buildTime } = await response.json();
                
                const lastVersion = localStorage.getItem(VERSION_KEY);
                
                if (!lastVersion) {
                    localStorage.setItem(VERSION_KEY, buildTime);
                } else if (lastVersion !== buildTime) {
                    setNewVersionAvailable(true);
                }
                setError(null);
            } catch (error) {
                console.error('Version check failed:', error);
                setError(error.message);
            }
        };

        checkVersion();
        const interval = setInterval(checkVersion, VERSION_CHECK_INTERVAL);
        return () => clearInterval(interval);
    }, []);

    const handleRefresh = async () => {
        try {
            const response = await fetch(`${process.env.PUBLIC_URL}/version.json?t=${Date.now()}`);
            const { buildTime } = await response.json();
            
            // Update localStorage before reload
            localStorage.setItem(VERSION_KEY, buildTime);
            
            // Clear cache and reload
            if ('caches' in window) {
                await Promise.all(
                    (await caches.keys()).map(name => caches.delete(name))
                );
            }
            setNewVersionAvailable(false);
            window.location.reload(true);
        } catch (error) {
            console.error('Failed to update version before refresh:', error);
            window.location.reload(true); // Reload anyway
        }
    };

    if (!newVersionAvailable) return null;

    return (
        <div
            style={{
                position: 'fixed',
                top: 20,
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1050
            }}
        >
            <Toast show={true}>
                <Toast.Body className="d-flex justify-content-between align-items-center">
                    <span>A new version is available</span>
                    <Button 
                        variant="primary" 
                        size="sm" 
                        onClick={handleRefresh}
                        className="ms-3"
                    >
                        Refresh Now
                    </Button>
                </Toast.Body>
            </Toast>
        </div>
    );
}