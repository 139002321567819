import React from "react";
import PartsPage from "./Tracking/PartsPage";

const Home = () => {
  return (
    <div>
      {/* <h2>Home Page</h2>
      <p>Welcome to the Home Page!</p> */}
      <PartsPage />
    </div>
  );
};

export default Home;
