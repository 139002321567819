import { Row, Col, Card, ListGroup, Badge, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatDate } from "../utils/formatters";

function PartsKanban({ 
  parts, 
  activeTab,
  departmentSteps,
  getDueDateColor
}) {
  const getPartsGroupedByStep = () => {
    const grouped = {};
    
    departmentSteps[activeTab]?.forEach(step => {
      grouped[step] = [];
    });

    parts.forEach(part => {
      const currentStep = part.steps.find(step => step.status === "in progress");
      if (currentStep) {
        if (!grouped[currentStep.name]) {
          grouped[currentStep.name] = [];
        }
        grouped[currentStep.name].push(part);
      }
    });

    return grouped;
  };

  return (
    <div className="container-fluid mb-5">
      <Row>
        {Object.entries(getPartsGroupedByStep()).map(([step, parts]) => (
          <Col key={step} xs={12} md={6} xl={4} xxl={3} className="mb-3">
            <StepCard step={step} parts={parts} getDueDateColor={getDueDateColor} />
          </Col>
        ))}
      </Row>
    </div>
  );
}

const StepCard = ({ step, parts, getDueDateColor }) => (
  <Card className="h-100 shadow-sm">
    <Card.Header className="d-flex justify-content-between align-items-center py-3 bg-light">
      <h6 className="mb-0 text-truncate me-2" style={{ maxWidth: '80%' }}>{step}</h6>
      <Badge bg="secondary" className="px-3 flex-shrink-0">
        {parts.length}
      </Badge>
    </Card.Header>
    <Card.Body className="p-0">
      <ListGroup variant="flush" 
        className="mt-2 px-2"
        style={{ 
          height: 'calc(100vh - 350px)',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        {parts.map((part) => (
          <ListGroup.Item
            key={part.id}
            action
            as={Link}
            to={`/part/${part.id}`}
            className={`${getDueDateColor(part.due_date)} p-3 mb-2 border border-medium-subtle rounded`}
          >
            <div className="d-flex justify-content-between align-items-start">
              <div>
                <strong>{part.part_unique_id}</strong>
                <div className="small text-muted">
                  Order: {part.purchase_order_id}
                </div>
                <div className={`small ${getDueDateColor(part.due_date)}`}>
                  Due: {formatDate(part.due_date)}
                </div>
              </div>
              <div className="text-end small">
                Qty: {part.qty_ordered}
              </div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card.Body>
  </Card>
);

export default PartsKanban;