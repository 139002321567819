import { useContext, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Card, ListGroup, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { PartsContext } from "../Context/PartsContext";
import BackButton from "./BackButton";
import axiosInstance from "../axios";
import { AuthContext } from "../Context/AuthContext";
import LoadingButton from "../Buttons/LoadingButton";
import { convertLocalMidnightToUtc, formatDate, formatDateISO, formatDateWithTime } from "../utils/formatters";
import { useNavigate } from "react-router-dom";

// Add truncateText helper function here too
const truncateText = (text, maxLength = 25) => {
  if (!text) return '';
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

// Add this helper function at the top of the file
const getStepDisplayStatus = (step, steps, stepIndex) => {
  const hasRejectedSteps = steps.some(s => s.status === "rejected");
  const firstNonApprovedIndex = steps.findIndex(s => s.status !== "approved");
  
  if (step.status === "approved") return "approved";
  if (step.status === "rejected") return "rejected";
  
  // If there are rejected steps or this isn't the first non-approved step
  if (hasRejectedSteps || (firstNonApprovedIndex !== -1 && stepIndex > firstNonApprovedIndex)) {
    return "not started";
  }
  
  return "in progress";
};

const formatHistoryDetails = (action, details) => {
  if (action === 'Upload Order' && details.action === 'Order Created') {
    const fields = [
      { key: 'part_name', label: 'Part Name' },
      { key: 'part_number', label: 'Part Number' },
      { key: 'quantity', label: 'Quantity' },
      { key: 'due_date', label: 'Due Date', format: (val) => formatDate(val) },
      { key: 'unit_price', label: 'Unit Price', format: (val) => `$${val.toFixed(2)}` },
      { key: 'revision', label: 'Revision', default: 'N/A' }
    ];

    return (
      <>
        {fields.map(({ key, label, format, default: defaultValue }) => {
          const value = details.details?.[key];
          if (value === null || ( value === undefined && defaultValue === undefined ) ) return null;
          
          return (
            <div key={key}>
              {label}: {format ? format(value) : (value || defaultValue)}
            </div>
          );
        })}
      </>
    );
  }

  // For other types of logs, format each field
  if (details && typeof details === 'object') {
    return Object.entries(details)
      .filter(([key, value]) => key !== 'part_id' && value !== undefined)
      .map(([key, value]) => {
        if (key === 'changes' && typeof value === 'object') {
          return Object.entries(value)
            .filter(([, val]) => val !== undefined)
            .map(([field, val]) => (
              <div key={field}>
                Changed {field.replace(/_/g, ' ')}: {
                  field === 'due_date' ? formatDate(val) : 
                  typeof val === 'object' ? JSON.stringify(val) : val
                }
              </div>
            ));
        }
        return (
          <div key={key}>
            {key.replace(/_/g, ' ')}: {
              key === 'due_date' ? formatDate(value) : 
              typeof value === 'object' ? JSON.stringify(value) : value
            }
          </div>
        );
      });
  }
  
  return <div>{JSON.stringify(details)}</div>;
};

const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

function PartDetails() {
  const { partId } = useParams();
  const navigate = useNavigate();
  const [printViewEnabled, setPrintViewEnabled] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editedPart, setEditedPart] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [partLoading, setPartLoading] = useState(true);

  const { parts, setParts } = useContext(PartsContext);
  const { hasMinimumRole } = useContext(AuthContext);

  const part = parts.find((p) => p.id == partId);

  useEffect(() => {
    const fetchPart = async () => {
      try {
        const response = await axiosInstance.get(`/part/${partId}`);
        setParts(prev => prev.map(p => 
          p.id === response.data.id ? response.data : p
        ));
      } catch (error) {
        console.error("Failed to fetch part:", error);
      } finally {
        setPartLoading(false);
      }
    };

    fetchPart();
  }, [partId]);

  useEffect(() => {
    if (part) {
      setEditedPart({
        part_unique_id: part.part_unique_id,
        name: part.name,
        qty_ordered: part.qty_ordered,
        unit_price: part.unit_price,
        due_date: formatDateISO(part.due_date),
        revision: part.revision || '',
      });
    }
  }, [part]);

  useEffect(() => {
    const fetchHistory = async () => {
      if (!part) return;
      try {
        const response = await axiosInstance.get('/partHistory', {
          params: { part_id: part.id }
        });
        setHistory(response.data);
      } catch (error) {
        console.error("Failed to fetch part history:", error);
      } finally {
        setHistoryLoading(false);
      }
    };

    fetchHistory();
  }, [part]);

  if (!part) {
    return <div>Part not found</div>;
  }

  const currentStep = part.steps.find(step => step.status === "in progress");
  const isComplete = !currentStep && part.steps.every(step => step.status === "approved");
  const isRejected = part.steps.some(step => step.status === "rejected");

  const handleDeletePart = async () => {
    setDeleteLoading(true);
    try {
      await axiosInstance.delete(`/deletePart`, {
        data: { part_id: part.id }
      });
      
      const updatedParts = parts.filter(p => p.id !== part.id);
      setParts(updatedParts);
      navigate('/');
    } catch (error) {
      console.error("Failed to delete part:", error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleEditPartSubmit = async () => {
    setEditLoading(true);
    try {
      // Compare with original part and only send changed fields
      const changes = {};
      if (editedPart.part_unique_id !== part.part_unique_id) {
        changes.part_unique_id = editedPart.part_unique_id;
      }
      if (editedPart.name !== part.name) {
        changes.name = editedPart.name;
      }
      if (Number(editedPart.qty_ordered) !== Number(part.qty_ordered)) {
        changes.qty_ordered = Number(editedPart.qty_ordered);
      }
      if (Number(editedPart.unit_price) !== Number(part.unit_price)) {
        changes.unit_price = Number(editedPart.unit_price);
      }
      
      const originalDate = part.due_date ? formatDateISO(part.due_date) : null;
      if (editedPart.due_date !== originalDate) {
        changes.due_date = convertLocalMidnightToUtc(editedPart.due_date);
      }
      
      if (editedPart.revision !== part.revision) {
        changes.revision = editedPart.revision;
      }
      
      if (Object.keys(changes).length > 0) {
        await axiosInstance.put("/updatePart", {
          part_id: part.id,
          ...changes,
        });

        const updatedParts = parts.map(p => {
          if (p.id === part.id) {
            return {
              ...p,
              ...changes,
              due_date: changes.due_date ? 
                convertLocalMidnightToUtc(changes.due_date).toISOString() : 
                p.due_date
            };
          }
          return p;
        });
        
        setParts(updatedParts);
      }
      setShowEditModal(false);
    } catch (error) {
      console.error("Failed to update part:", error);
    } finally {
      setEditLoading(false);
    }
  };

  // Function to render status icon for each step
  const renderStatusIcon = (status) => {
    if (status === "approved") {
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          style={{ color: "green", marginRight: "10px" }}
        />
      );
    } else if (status === "rejected") {
      return (
        <FontAwesomeIcon
          icon={faTimesCircle}
          style={{ color: "red", marginRight: "10px" }}
        />
      );
    }
    return null;
  };

  const formatInstructions = (instructions) => {
    return instructions.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const formatStepInstruction = (step) => {
    if (!step.instruction) return "No instructions provided";
    
    return step.instruction.split('\n').map((line, index) => (
      <div key={index} className="mb-1">
        {line}
      </div>
    ));
  };

  return (
    <>
      <Card style={{ position: 'relative' }}>
        {(partLoading || historyLoading) && (
          <div style={overlayStyle}>
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <Card.Body>
          <Row className="align-items-center mb-4">
            <Col xs="auto">
              <BackButton />
            </Col>
            <Col>
              <h4 className="mb-0">
                {part.part_unique_id}
                {part.name && (
                  <small 
                    className="text-muted ms-2" 
                    title={part.name}
                  >
                    {truncateText(part.name)}
                  </small>
                )}
              </h4>
            </Col>
            <Col xs="auto">
              {isComplete ? (
                <div className="text-success">
                  <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                  Complete
                </div>
              ) : isRejected ? (
                <div className="text-danger">
                  <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                  Rejected
                </div>
              ) : (
                <div className="text-secondary">In Progress</div>
              )}
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <strong>Due Date:</strong>{" "}
                  {formatDate(part.due_date)}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Revision:</strong>{" "}
                  {part.revision || 'N/A'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Customer:</strong> {part.customer_name}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Quantity:</strong> {part.qty_ordered}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Unit Price:</strong> ${part.unit_price}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>PO Number:</strong> {part.purchase_order_id}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>

          {hasMinimumRole('admin') && (
            <Row className="mb-4">
              <Col>
                <div className="d-flex gap-2">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => setShowEditModal(true)}
                  >
                    Edit Part
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete Part
                  </Button>
                </div>
              </Col>
            </Row>
          )}

          <div className="mb-4">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="mb-0">Process Steps</h5>
              <Button 
                variant="outline-primary" 
                size="sm"
                onClick={() => setPrintViewEnabled(!printViewEnabled)}
              >
                {printViewEnabled ? "Collapse Details" : "Show Details"}
              </Button>
            </div>
            <ListGroup>
              {part.steps.map((step, index) => (
                <ListGroup.Item
                  key={step.index}
                  as={Link}
                  to={`/part/${partId}/step/${step.index}`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {printViewEnabled ? (
                    <Card.Body>
                      <Card.Title className="align-middle">
                        {step.name}
                      </Card.Title>
                      <Card.Text>
                        <strong>Status: </strong>
                        {getStepDisplayStatus(step, part.steps, index)}
                      </Card.Text>
                      <Card.Text>
                        <strong>Instructions:</strong>
                        <div className="mt-2">
                          {formatStepInstruction(step)}
                        </div>
                      </Card.Text>
                      {step.status !== "in progress" && (
                        <>
                          <Card.Text>
                            <strong>Comment: </strong>
                            {step.comment || "No comment provided"}
                          </Card.Text>
                          <Card.Text>
                            <strong>Updated By: </strong>
                            {step.updated_by || "Unknown"}
                          </Card.Text>
                          <Card.Text>
                            <strong>Updated At: </strong>
                            {formatDateWithTime(step.updated_at) || "Unknown"}
                          </Card.Text>
                        </>
                      )}
                    </Card.Body>
                  ) : (
                    <div className="d-flex align-items-center w-100">
                      {renderStatusIcon(step.status)}
                      <span>{step.name}</span>
                    </div>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>

          <div className="mt-4">
            <h5>History</h5>
            <ListGroup>
              {historyLoading ? (
                <ListGroup.Item>Loading history...</ListGroup.Item>
              ) : history.length === 0 ? (
                <ListGroup.Item>No history available</ListGroup.Item>
              ) : (
                history.map((entry, index) => (
                  <ListGroup.Item key={index}>
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <div className="fw-bold">{entry.action}</div>
                        <div className="text-muted small">
                          {formatHistoryDetails(entry.action, entry.details)}
                        </div>
                      </div>
                      <div className="text-end">
                        <div className="small">{entry.username}</div>
                        <div className="text-muted small">
                          {formatDateWithTime(entry.timestamp)}
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))
              )}
            </ListGroup>
          </div>

        </Card.Body>
      </Card>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete part {part.part_unique_id}? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="danger"
            onClick={handleDeletePart}
            text="Delete Part"
            loadingText="Deleting..."
            isLoading={deleteLoading}
          />
        </Modal.Footer>
      </Modal>

      {/* Edit Modal */}
      <Modal 
        show={showEditModal} 
        onHide={() => {
          setShowEditModal(false);
          // Reset form
          setEditedPart({
            part_unique_id: part.part_unique_id,
            name: part.name,
            qty_ordered: part.qty_ordered,
            unit_price: part.unit_price,
            due_date: formatDateISO(part.due_date),
            revision: part.revision || '',
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Part</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Part Number</Form.Label>
              <Form.Control
                type="text"
                value={editedPart?.part_unique_id || ''}
                onChange={(e) => setEditedPart({
                  ...editedPart,
                  part_unique_id: e.target.value
                })}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Part Name</Form.Label>
              <Form.Control
                type="text"
                value={editedPart?.name || ''}
                onChange={(e) => setEditedPart({
                  ...editedPart,
                  name: e.target.value
                })}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Due Date</Form.Label>
              <Form.Control
                type="date"
                value={editedPart?.due_date || ''}
                onChange={(e) => setEditedPart({
                  ...editedPart,
                  due_date: e.target.value
                })}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                value={editedPart?.qty_ordered || ''}
                onChange={(e) => setEditedPart({
                  ...editedPart,
                  qty_ordered: e.target.value
                })}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Unit Price</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                value={editedPart?.unit_price || ''}
                onChange={(e) => setEditedPart({
                  ...editedPart,
                  unit_price: e.target.value
                })}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Revision</Form.Label>
              <Form.Control
                type="text"
                value={editedPart?.revision || ''}
                onChange={(e) => setEditedPart({
                  ...editedPart,
                  revision: e.target.value
                })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={() => setShowEditModal(false)}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="primary"
            onClick={handleEditPartSubmit}
            text="Save Changes"
            loadingText="Saving..."
            isLoading={editLoading}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PartDetails;
