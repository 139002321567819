import { createContext, useState, useEffect, useContext } from "react";
import axiosInstance from "../axios";
import { AuthContext } from "./AuthContext";

export const PartsContext = createContext();

export const PartsProvider = ({ children }) => {
  const [parts, setParts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (!isAuthenticated) {
      setLoading(false);
      return;
    }
    
    setLoading(true);
    axiosInstance.get("/parts")
      .then((res) => {
        if (res.status == "401") return;
        setParts(res.data);
      })
      .catch(error => {
        console.error("Failed to fetch parts:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isAuthenticated]);

  return (
    <PartsContext.Provider value={{ parts, setParts, loading }}>
      {children}
    </PartsContext.Provider>
  );
};
