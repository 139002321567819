import axios from "axios";
import Cookies from "js-cookie";
import { AUTH_CONFIG } from './config/auth';

const baseURL = process.env.REACT_APP_NODE_ENV === "prod"
    ? process.env.REACT_APP_BACKEND_URL_PROD
    : process.env.REACT_APP_BACKEND_URL_DEV;

const UNPROTECTED_ROUTES = ['/login', '/register', '/refresh-token'];

const axiosInstance = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

axiosInstance.interceptors.request.use(
    (config) => {
        if (UNPROTECTED_ROUTES.includes(config.url)) {
            return config;
        }

        const token = Cookies.get("jwt");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (UNPROTECTED_ROUTES.includes(error.config?.url)) {
            return Promise.reject(error);
        }

        if (error.response?.status !== 401 && error.response?.status !== 403) {
            return Promise.reject(error);
        }

        console.log("Attempting token refresh...");
        const originalRequest = error.config;
        if (originalRequest._retry) {
            console.warn("Token refresh loop detected:", {
                url: originalRequest.url,
                method: originalRequest.method
            });
            return Promise.reject(error);
        }

        try {
            originalRequest._retry = true;
            const refreshToken = Cookies.get("refreshToken");

            if (!refreshToken) {
                console.warn("No refresh token found during token refresh attempt");
                throw new Error("No refresh token");
            }

            console.log("Calling refresh token endpoint...");
            const { data } = await axios.post(
                `${baseURL}/refresh-token`,
                { refreshToken },
                { withCredentials: true }
            );
            console.log("Refresh token success, new token received");

            Cookies.set("jwt", data.accessToken, AUTH_CONFIG.COOKIE_OPTIONS.jwt);

            originalRequest.headers["Authorization"] = `Bearer ${data.accessToken}`;
            return axiosInstance(originalRequest);
        } catch (err) {
            console.error("Token refresh failed:", {
                error: err.message,
                originalUrl: originalRequest.url,
                status: err.response?.status,
                responseData: err.response?.data
            });

            Cookies.remove("jwt");
            Cookies.remove("refreshToken");
            window.location.href = '/login';
            return Promise.reject(error);
        }
    }
);

export default axiosInstance;
