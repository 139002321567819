import { useState, useEffect } from 'react';
import { Card, ListGroup, Button, Modal, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash, faTimes, faPlus, faSave, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from './axios';
import LoadingButton from './Buttons/LoadingButton';

export default function WorkflowTemplateManager({ setError, setSuccessMessage }) {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    steps: []
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axiosInstance.get('/workflowTemplates');
      setTemplates(response.data);
    } catch (error) {
      setError('Failed to fetch workflow templates');
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    // Check for blank step names
    const invalidSteps = formData.steps.filter(step => !step.name.trim());
    if (invalidSteps.length > 0) {
      setError('All steps must have names');
      return false;
    }

    // Check for blank instruction names
    for (const step of formData.steps) {
      const invalidInstructions = step.instructions.filter(inst => !inst.name.trim());
      if (invalidInstructions.length > 0) {
        setError(`All instructions in step "${step.name}" must have names`);
        return false;
      }
    }

    return true;
  };

  const handleAddTemplate = async () => {
    if (!validateForm()) return;
    
    setIsSubmitting(true);
    try {
      await axiosInstance.post('/workflowTemplates', formData);
      setSuccessMessage('Workflow template created successfully');
      fetchTemplates();
      setShowAddModal(false);
      setFormData({ name: '', steps: [] });
    } catch (error) {
      setError(error.response?.data || 'Failed to create workflow template');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditTemplate = async () => {
    if (!validateForm()) return;
    
    setIsSubmitting(true);
    try {
      await axiosInstance.put(`/workflowTemplates/${selectedTemplate.id}`, formData);
      setSuccessMessage('Workflow template updated successfully');
      fetchTemplates();
      setShowEditModal(false);
    } catch (error) {
      setError(error.response?.data || 'Failed to update workflow template');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteTemplate = async () => {
    setIsSubmitting(true);
    try {
      await axiosInstance.delete(`/workflowTemplates/${selectedTemplate.id}`);
      setSuccessMessage('Workflow template deleted successfully');
      fetchTemplates();
      setShowDeleteModal(false);
    } catch (error) {
      setError(error.response?.data || 'Failed to delete workflow template');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddStep = () => {
    setFormData(prev => ({
      ...prev,
      steps: [...prev.steps, { 
        name: '', 
        instructions: [{ name: '', description: '' }] // Initialize with one empty instruction
      }]
    }));
  };

  const handleAddInstruction = (stepIndex) => {
    setFormData(prev => ({
      ...prev,
      steps: prev.steps.map((step, i) => 
        i === stepIndex 
          ? {
              ...step,
              instructions: [...step.instructions, { name: '', description: '' }]
            }
          : step
      )
    }));
  };

  const handleRemoveInstruction = (stepIndex, instructionIndex) => {
    setFormData(prev => ({
      ...prev,
      steps: prev.steps.map((step, i) => 
        i === stepIndex 
          ? {
              ...step,
              instructions: step.instructions.filter((_, j) => j !== instructionIndex)
            }
          : step
      )
    }));
  };

  const handleInstructionChange = (stepIndex, instructionIndex, field, value) => {
    setFormData(prev => ({
      ...prev,
      steps: prev.steps.map((step, i) => 
        i === stepIndex 
          ? {
              ...step,
              instructions: step.instructions.map((instruction, j) =>
                j === instructionIndex 
                  ? { ...instruction, [field]: value }
                  : instruction
              )
            }
          : step
      )
    }));
  };

  const handleRemoveStep = (index) => {
    setFormData(prev => ({
      ...prev,
      steps: prev.steps.filter((_, i) => i !== index)
    }));
  };

  const handleStepChange = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      steps: prev.steps.map((step, i) => 
        i === index ? { ...step, [field]: value } : step
      )
    }));
  };

  const handleEditClick = (template) => {
    const formattedTemplate = {
      id: template.id,
      name: template.name,
      steps: template.steps.map(step => ({
        name: step.name,
        instructions: step.available_instructions || [],
        selected_instruction_id: step.current_instruction?.id
      }))
    };
    
    setFormData(formattedTemplate);
    setSelectedTemplate(template);
    setShowEditModal(true);
  };

  const handleReorderStep = (index, direction) => {
    setFormData(prev => {
      const newSteps = [...prev.steps];
      if (direction === 'up' && index > 0) {
        [newSteps[index], newSteps[index - 1]] = [newSteps[index - 1], newSteps[index]];
      } else if (direction === 'down' && index < newSteps.length - 1) {
        [newSteps[index], newSteps[index + 1]] = [newSteps[index + 1], newSteps[index]];
      }
      return { ...prev, steps: newSteps };
    });
  };

  return (
    <Card className="mt-4">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>Workflow Templates</h4>
          <Button variant="primary" onClick={() => {
            setFormData({ name: '', steps: [] });
            setShowAddModal(true);
          }}>
            Create Template
          </Button>
        </div>

        <ListGroup>
          {templates.map(template => (
            <ListGroup.Item
              key={template.id}
              className="d-flex justify-content-between align-items-center"
            >
              <div>{template.name}</div>
              <div>
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="me-2"
                  onClick={() => handleEditClick(template)}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => {
                    setSelectedTemplate(template);
                    setShowDeleteModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>

        {/* Add/Edit Modal */}
        <Modal
          show={showAddModal || showEditModal}
          onHide={() => {
            setShowAddModal(false);
            setShowEditModal(false);
            setSelectedTemplate(null);
            setFormData({ name: '', steps: [] });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {showAddModal ? 'Add New Template' : 'Edit Template'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-4">
                <Form.Label className="fw-bold">Template Name</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  placeholder="Enter template name"
                />
              </Form.Group>

              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="mb-0">Steps</h5>
                  <Button variant="outline-primary" size="sm" onClick={handleAddStep}>
                    <FontAwesomeIcon icon={faPlus} /> Add Step
                  </Button>
                </div>

                {formData.steps.map((step, stepIndex) => (
                  <div key={stepIndex} className="border rounded p-3 mb-3 bg-light">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h6 className="mb-0 fw-bold">Step {stepIndex + 1}</h6>
                      <div className="d-flex gap-2">
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          onClick={() => handleReorderStep(stepIndex, 'up')}
                          disabled={stepIndex === 0}
                        >
                          <FontAwesomeIcon icon={faArrowUp} />
                        </Button>
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          onClick={() => handleReorderStep(stepIndex, 'down')}
                          disabled={stepIndex === formData.steps.length - 1}
                        >
                          <FontAwesomeIcon icon={faArrowDown} />
                        </Button>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => handleRemoveStep(stepIndex)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </div>
                    </div>

                    <Form.Group className="mb-3">
                      <Form.Label>Step Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={step.name}
                        onChange={(e) => handleStepChange(stepIndex, 'name', e.target.value)}
                        placeholder="Enter step name"
                      />
                    </Form.Group>

                    <div className="instructions-container">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <h6 className="mb-0 text-muted">Available Instructions</h6>
                        <Button 
                          variant="outline-secondary" 
                          size="sm"
                          onClick={() => handleAddInstruction(stepIndex)}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Add Option
                        </Button>
                      </div>

                      {step.instructions.map((instruction, instructionIndex) => (
                        <div 
                          key={instructionIndex} 
                          className="border rounded p-3 mb-2 bg-white"
                        >
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <small className="text-muted">Option {instructionIndex + 1}</small>
                            <Button
                              variant="outline-danger"
                              size="sm"
                              onClick={() => handleRemoveInstruction(stepIndex, instructionIndex)}
                              disabled={step.instructions.length === 1}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </div>

                          <Form.Group className="mb-2">
                            <Form.Label className="small">Name</Form.Label>
                            <Form.Control
                              type="text"
                              size="sm"
                              value={instruction.name}
                              onChange={(e) => handleInstructionChange(
                                stepIndex,
                                instructionIndex,
                                'name',
                                e.target.value
                              )}
                              placeholder="Enter instruction name"
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label className="small">Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              size="sm"
                              rows={2}
                              value={instruction.description}
                              onChange={(e) => handleInstructionChange(
                                stepIndex,
                                instructionIndex,
                                'description',
                                e.target.value
                              )}
                              placeholder="Enter instruction details"
                              style={{ whiteSpace: 'pre-wrap' }}
                            />
                          </Form.Group>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowAddModal(false);
                setShowEditModal(false);
                setSelectedTemplate(null);
                setFormData({ name: '', steps: [] });
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="primary"
              onClick={showAddModal ? handleAddTemplate : handleEditTemplate}
              text={showAddModal ? 'Create Template' : 'Save Changes'}
              loadingText="Saving..."
              isLoading={isSubmitting}
            />
          </Modal.Footer>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          show={showDeleteModal}
          onHide={() => {
            setShowDeleteModal(false);
            setSelectedTemplate(null);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the template "{selectedTemplate?.name}"?
            This action cannot be undone.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowDeleteModal(false);
                setSelectedTemplate(null);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="danger"
              onClick={handleDeleteTemplate}
              text="Delete Template"
              loadingText="Deleting..."
              isLoading={isSubmitting}
            />
          </Modal.Footer>
        </Modal>
      </Card.Body>
    </Card>
  );
} 