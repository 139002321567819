import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Nav, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import { PartsContext } from "../Context/PartsContext";
import PartsList from "./PartsList";
import PartsKanban from "./PartsKanban";
import SearchBar from './SearchBar';
import axiosInstance from "../axios";

// Utility functions moved from child components
const getDueDateColor = (dueDate) => {
  const today = new Date();
  const due = new Date(dueDate);
  const diffDays = Math.ceil((due - today) / (1000 * 60 * 60 * 24));

  if (diffDays < 0) return 'text-danger';
  if (diffDays < 7) return 'text-warning-emphasis';
  if (diffDays < 14) return 'text-warning';
  return 'text-success';
};

const getPartStatus = (part) => {
  const rejectedStep = part.steps.find(step => step.status === "rejected");
  if (rejectedStep) {
    return `Rejected: ${rejectedStep.name}`;
  }

  const inProgressStep = part.steps.find(step => step.status === "in progress");
  if (inProgressStep) {
    return inProgressStep.name;
  }

  return "Finished";
};

const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

function PartsPage() {
  const { hasMinimumRole } = useContext(AuthContext);
  const { parts, loading } = useContext(PartsContext);
  const [sortField, setSortField] = useState('due_date');
  const [sortDirection, setSortDirection] = useState('asc');
  const [activeTab, setActiveTab] = useState('all');
  const [departments, setDepartments] = useState([]);
  const [departmentSteps, setDepartmentSteps] = useState({});
  const [viewMode, setViewMode] = useState(() => {
    return window.innerWidth < 576 ? 'list' : 'steps';
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [departmentsLoading, setDepartmentsLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576) {
        setViewMode('list');
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchDepartments = async () => {
      setDepartmentsLoading(true);
      try {
        const response = await axiosInstance.get('/departments');
        setDepartments(response.data);
        
        const stepsMap = {};
        response.data.forEach(dept => {
          stepsMap[dept.name.toLowerCase()] = dept.steps?.map(step => step.name);
        });
        setDepartmentSteps(stepsMap);
      } catch (error) {
        console.error('Failed to fetch departments:', error);
      } finally {
        setDepartmentsLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  const filterPartsByDepartment = (parts, department) => {
    const steps = departmentSteps[department];
    if (!steps) return parts;
    
    return parts.filter(part => {
      const currentStep = part.steps.find(step => step.status === "in progress");
      return currentStep && steps.includes(currentStep.name);
    });
  };

  const getFilteredParts = () => {
    let filtered = activeTab === 'all' 
      ? parts 
      : filterPartsByDepartment(parts, activeTab);

    if (!searchTerm) return filtered;

    const searchLower = searchTerm.toLowerCase();
    return filtered.filter(part => 
      part.part_unique_id.toLowerCase().includes(searchLower) ||
      part.purchase_order_id.toLowerCase().includes(searchLower)
    );
  };

  const getSortedParts = () => {
    return [...getFilteredParts()].sort((a, b) => {
      const direction = sortDirection === 'asc' ? 1 : -1;
      if (sortField === 'due_date') {
        return direction * ((new Date(a.due_date || 0)) - (new Date(b.due_date || 0)));
      }
      return direction * (a[sortField] < b[sortField] ? -1 : 1);
    });
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleSort = (field) => {
    setSortDirection(current => current === 'asc' ? 'desc' : 'asc');
    setSortField(field);
  };

  const renderDepartmentTabs = () => (
    <Row className="mb-4">
      <Col>
        <Nav variant="tabs" className="border-0 gap-1">
          <Nav.Item>
            <Nav.Link
              active={activeTab === 'all'}
              onClick={() => setActiveTab('all')}
              className="px-4"
            >
              All Parts
            </Nav.Link>
          </Nav.Item>
          {departments?.map(dept => (
            <Nav.Item key={dept.id}>
              <Nav.Link
                active={activeTab === dept.name.toLowerCase()}
                onClick={() => setActiveTab(dept.name.toLowerCase())}
                className="px-4"
              >
                {dept.name}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Col>
    </Row>
  );

  return (
    <Container fluid className="px-4" style={{ position: 'relative' }}>
      {(loading || departmentsLoading) && (
        <div style={overlayStyle}>
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      
      <Row className="mb-4">
        <Col xs={12} className="d-flex flex-wrap gap-3 align-items-center">
          <div className="d-flex gap-3 align-items-center flex-grow-1">
            {hasMinimumRole('admin') && (
              <Button 
                variant="primary" 
                as={Link} 
                to="/create-order" 
                className="flex-shrink-0 px-4"
              >
                Create New Order
              </Button>
            )}
            <div className="flex-grow-1" style={{ minWidth: '300px' }}>
              <SearchBar onSearch={handleSearch} />
            </div>
          </div>

          <div className="d-flex gap-3 flex-shrink-0">
            <Form.Select 
              onChange={(e) => handleSort(e.target.value)}
              value={sortField}
              style={{ width: '150px' }}
            >
              <option value="due_date">Due Date</option>
              <option value="part_unique_id">Part ID</option>
            </Form.Select>
            <Button 
              variant="outline-secondary"
              onClick={() => setSortDirection(d => d === 'asc' ? 'desc' : 'asc')}
            >
              {sortDirection === 'asc' ? '↑' : '↓'}
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setViewMode(viewMode === 'list' ? 'steps' : 'list')}
              style={{ width: '120px' }}
            >
              {viewMode === 'list' ? 'Step View' : 'List View'}
            </Button>
          </div>
        </Col>
      </Row>

      {renderDepartmentTabs()}

      {viewMode === 'steps' ? (
        <PartsKanban 
          parts={loading || departmentsLoading ? [] : getSortedParts()}
          activeTab={activeTab}
          departmentSteps={departmentSteps}
          getDueDateColor={getDueDateColor}
        />
      ) : (
        <PartsList 
          parts={loading || departmentsLoading ? [] : getSortedParts()}
          getDueDateColor={getDueDateColor}
          getPartStatus={getPartStatus}
        />
      )}
    </Container>
  );
}

export default PartsPage;