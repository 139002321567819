import { useState } from 'react';
import { ListGroup, Button, Modal } from 'react-bootstrap';
import { Document, Page } from 'react-pdf';
import LoadingButton from '../Buttons/LoadingButton';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import axiosInstance from '../axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf-worker/pdf.worker.min.js';

function FileAttachments({ files = [], onRemove, isUploaded = false, setErrorMessage, onFileDeleted }) {
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingPreview, setLoadingPreview] = useState({});
  const [loadingDownload, setLoadingDownload] = useState({});
  const [loadingDelete, setLoadingDelete] = useState({});

  const getFileName = (file) => {
    if (isUploaded) {
      const urlParts = file.file_url.split('/');
      const fullName = decodeURIComponent(urlParts[urlParts.length - 1]);
      const nameParts = fullName.split('-');
      if (nameParts.length > 2) {
        return nameParts.slice(2).join('-');
      }
      return fullName;
    }
    return file.name;
  };

  const getFileType = (fileName) => {
    const ext = fileName.toLowerCase().split('.').pop();
    if (ext === 'pdf') return 'pdf';
    if (['jpg', 'jpeg', 'png'].includes(ext)) return 'image';
    return null;
  };

  const handlePreview = async (file) => {
    setLoadingPreview(prev => ({ ...prev, [file.id]: true }));
    try {
      const response = await fetch(file.signedUrl);
      if (!response.ok) throw new Error('Preview failed');
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      
      const fileType = getFileType(getFileName(file));
      setSelectedFileType(fileType);
      setSelectedFileUrl(url);
    } catch (error) {
      console.error('Preview failed:', error);
      setErrorMessage('Failed to preview file. Please try again.');
    } finally {
      setLoadingPreview(prev => ({ ...prev, [file.id]: false }));
    }
  };

  const handleDownload = async (file) => {
    setLoadingDownload(prev => ({ ...prev, [file.id]: true }));
    try {
      const response = await fetch(file.signedUrl);
      if (!response.ok) throw new Error('Download failed');
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      
      const link = document.createElement('a');
      link.href = url;
      link.download = getFileName(file);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
      setErrorMessage('Failed to download file. Please try again.');
    } finally {
      setLoadingDownload(prev => ({ ...prev, [file.id]: false }));
    }
  };

  const calculateOptimalScale = () => {
    const modalWidth = Math.min(window.innerWidth * 0.8, 800);
    const modalHeight = window.innerHeight * 0.6;
    return {
        width: modalWidth,
        height: modalHeight,
    };
  };

  const handleDelete = async (file) => {
    setLoadingDelete(prev => ({ ...prev, [file.id]: true }));
    try {
      await axiosInstance.delete(
        `/deleteAttachment/${file.part_id}/${file.step_index}/${file.id}`
      );
      onFileDeleted(file.id);
    } catch (error) {
      console.error('Delete failed:', error);
      setErrorMessage('Failed to delete file. Please try again.');
    } finally {
      setLoadingDelete(prev => ({ ...prev, [file.id]: false }));
    }
  };

  const closePreview = () => {
    if (selectedFileUrl) {
      window.URL.revokeObjectURL(selectedFileUrl);
    }
    setSelectedFileUrl(null);
    setSelectedFileType(null);
    setPageNumber(1);
    setNumPages(null);
  };

  return (
    <>
      <ListGroup>
        {files.map((file, index) => (
          <ListGroup.Item 
            key={isUploaded ? file.id : index} 
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <i className="bi bi-paperclip me-2"></i>
              {getFileName(file)}
              {isUploaded && (
                <small className="text-muted ms-2">
                  {new Date(file.created_at).toLocaleDateString()}
                </small>
              )}
            </div>
            <div>
              {isUploaded ? (
                <>
                  {getFileType(getFileName(file)) && (
                    <LoadingButton
                      variant="outline-secondary"
                      size="sm"
                      className="me-2"
                      onClick={() => handlePreview(file)}
                      isLoading={loadingPreview[file.id]}
                      text="Preview"
                      loadingText="Loading..."
                    />
                  )}
                  <LoadingButton
                    variant="outline-primary"
                    size="sm"
                    className="me-2"
                    onClick={() => handleDownload(file)}
                    isLoading={loadingDownload[file.id]}
                    text={<><i className="bi bi-download me-1"></i>Download</>}
                    loadingText="Downloading..."
                  />
                  <LoadingButton
                    variant="outline-danger"
                    size="sm"
                    onClick={() => handleDelete(file)}
                    isLoading={loadingDelete[file.id]}
                    text={<><FontAwesomeIcon icon={faTrash} /> Delete</>}
                    loadingText="Deleting..."
                  />
                </>
              ) : (
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => onRemove(index)}
                >
                  Remove
                </Button>
              )}
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <Modal
        show={!!selectedFileUrl}
        onHide={closePreview}
        size="lg"
        dialogClassName="!max-w-[90%]"
      >
        <Modal.Header closeButton>
          <Modal.Title>File Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="text-center max-h-[80vh] overflow-auto">
            {selectedFileType === 'pdf' ? (
              <>
                <Document
                  file={selectedFileUrl}
                  onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  loading={<span>Loading PDF...</span>}
                >
                  <Page 
                    pageNumber={pageNumber} 
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    {...calculateOptimalScale()}
                  />
                </Document>
                {numPages > 1 && (
                  <div className="my-3">
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => setPageNumber(page => Math.max(1, page - 1))}
                      disabled={pageNumber <= 1}
                      className="mr-2"
                    >
                      Previous
                    </Button>
                    <span className="mx-2">
                      Page {pageNumber} of {numPages}
                    </span>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => setPageNumber(page => Math.min(numPages, page + 1))}
                      disabled={pageNumber >= numPages}
                      className="ml-2"
                    >
                      Next
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <img src={selectedFileUrl} alt="Preview" className="max-h-[80vh] w-full" />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FileAttachments; 