import { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Card, Form, Alert, Row, Col, Tooltip, OverlayTrigger, ListGroup, Modal, Spinner } from "react-bootstrap";
import { PartsContext } from "../Context/PartsContext";
import { AuthContext } from "../Context/AuthContext";
import BackButton from "./BackButton";
import axiosInstance from "../axios";
import LoadingButton from "../Buttons/LoadingButton";
import { formatDateWithTime } from '../utils/formatters';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { generateCoC } from '../Tracking/Orders/CoCGenerator';
import FileAttachments from "./FileAttachments";

function StepDetails() {
  const { partId, stepId } = useParams();
  const navigate = useNavigate();
  const [stepLoading, setStepLoading] = useState(true);
  const [attachmentsLoading, setAttachmentsLoading] = useState(true);
  const { parts, setParts } = useContext(PartsContext);
  const { username } = useContext(AuthContext);
  const [comment, setComment] = useState("");
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [nextStatus, setNextStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [orderParts, setOrderParts] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  const part = parts.find(p => p.id == partId);
  const step = part?.steps?.find(s => s.index == stepId);

  const isFirstInProgressStep = part.steps.findIndex((s) => s.status === "in progress") === part.steps.indexOf(step);

  useEffect(() => {
    const fetchPart = async () => {
      try {
        const response = await axiosInstance.get(`/part/${partId}`);
        setParts(prev => prev.map(p => 
          p.id === response.data.id ? response.data : p
        ));
      } catch (error) {
        console.error("Failed to fetch part:", error);
      } finally {
        setStepLoading(false);
      }
    };

    fetchPart();
  }, [partId]);

  useEffect(() => {
    const fetchAttachments = async () => {
      if (!part || !step) return;
      try {
        const response = await axiosInstance.get(`/stepAttachments/${partId}/${step.index}`);
        const attachmentsWithMetadata = response.data.map(attachment => ({
          ...attachment,
          part_id: partId,
          step_index: step.index
        }));
        setAttachments(attachmentsWithMetadata);
      } catch (error) {
        console.error("Failed to fetch attachments:", error);
      } finally {
        setAttachmentsLoading(false);
      }
    };

    fetchAttachments();
  }, [part, step]);

  useEffect(() => {
    const fetchOrderParts = async () => {
      if (!part) return;
      try {
        const response = await axiosInstance.get(`/order/${part.order_id}/parts`);
        setOrderParts(response.data);
      } catch (error) {
        console.error("Failed to fetch order parts:", error);
      }
    };

    fetchOrderParts();
  }, [part]);

  if (!part || !step) {
    return <div>Step not found</div>;
  }

  const updateStatus = async (newStatus) => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const formData = new FormData();
      formData.append('step_index', String(step.index));
      formData.append('part_id', String(partId));
      formData.append('status', newStatus);
      formData.append('comment', comment);

      const response = await axiosInstance.post("/updateStepStatus", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      updateStepsLocal(newStatus);
      setComment("");
      setShowCommentBox(false);
    } catch (error) {
      console.error("Failed to update order:", error);
      setErrorMessage(error.response?.data || "Failed to update the status. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const updateStepsLocal = (newStatus) => {
    const updatedParts = parts.map((p) => {
      if (p.id == partId) {
        return {
          ...p,
          steps: p.steps.map((s) =>
            s.index == stepId
              ? {
                  ...s,
                  status: newStatus,
                  comment,
                  updated_by: username,
                  updated_at: new Date().toISOString(),
                }
              : s
          ),
        };
      }
      return p;
    });
    setParts(updatedParts);
  };

  const handleApprove = () => updateStatus("approved");
  const handleReject = () => updateStatus("rejected");
  const handleUnApprove = () => updateStatus("in progress");
  const handleUnReject = () => updateStatus("in progress");

  const handleShowCommentBox = (s) => {
    setNextStatus(s);
    setShowCommentBox(true);
  };
  const handleHideCommentBox = () => {
    setShowCommentBox(false);
    setNextStatus(null);
    setComment("");
    setErrorMessage("");
  };

  const formatInstructions = (instructions) => {
    return instructions?.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const getStepDisplayStatus = (step, steps, stepIndex) => {
    const hasRejectedSteps = steps.some(s => s.status === "rejected");
    const firstNonApprovedIndex = steps.findIndex(s => s.status !== "approved");
    
    if (step.status === "approved") return "approved";
    if (step.status === "rejected") return "rejected";
    
    if (hasRejectedSteps || (firstNonApprovedIndex !== -1 && stepIndex > firstNonApprovedIndex)) {
      return "not started";
    }
    
    return "in progress";
  };

  const getStatusIcon = (status) => {
    const displayStatus = getStepDisplayStatus(step, part.steps, part.steps.indexOf(step));
    
    if (displayStatus === "approved") {
      return (
        <div className="text-success">
          Approved
        </div>
      );
    } else if (displayStatus === "rejected") {
      return (
        <div className="text-danger">
          Rejected
        </div>
      );
    } else if (displayStatus === "not started") {
      return (
        <div className="text-secondary">
          Not Started
        </div>
      );
    }
    return (
      <div className="text-secondary">
        In Progress
      </div>
    );
  };

  const hasRejectedSteps = (steps, currentStepIndex) => {
    return steps
      .slice(0, currentStepIndex)
      .some((step) => step.status === "rejected");
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {hasRejectedSteps(part.steps, part.steps.indexOf(step))
        ? "Cannot approve/reject step when previous steps are rejected"
        : "You can only approve/reject the first step in progress"}
    </Tooltip>
  );

  const ActionButton = ({ variant, action, text }) => {
    const disabled = !isFirstInProgressStep || hasRejectedSteps(part.steps, part.steps.indexOf(step));
    const button = (
      <Button
        variant={variant}
        className="me-2"
        onClick={() => handleShowCommentBox(action)}
        disabled={disabled}
        style={{ pointerEvents: disabled ? 'none' : 'auto' }}
      >
        {text}
      </Button>
    );

    if (disabled) {
      return (
        <OverlayTrigger placement="top" overlay={renderTooltip}>
          <span className="d-inline-block">{button}</span>
        </OverlayTrigger>
      );
    }

    return button;
  };


  const isCoCStep = step?.name.toLowerCase().includes('create certificate of conformance');

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const handleFileDeleted = (fileId) => {
    setAttachments(prevAttachments => 
      prevAttachments.filter(attachment => attachment.id !== fileId)
    );
  };

  const handleFileUpload = async () => {
    setUploadLoading(true);
    setErrorMessage("");
    try {
      const formData = new FormData();
      formData.append('part_id', String(partId));
      formData.append('step_index', String(step.index));
      
      selectedFiles.forEach(file => {
        formData.append('files', file);
      });

      const response = await axiosInstance.post("/uploadStepFiles", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      if (response.data.attachments) {
        const newAttachments = response.data.attachments.map(attachment => ({
          ...attachment,
          part_id: partId,
          step_index: step.index
        }));
        setAttachments(prev => [...newAttachments, ...prev]);
      }

      setSelectedFiles([]);
    } catch (error) {
      console.error("Failed to upload files:", error);
      setErrorMessage(error.response?.data || "Failed to upload files. Please try again.");
    } finally {
      setUploadLoading(false);
    }
  };

  const handleUploadFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(prev => [...prev, ...files]);
  };

  const handleRemoveUploadFile = (fileToRemove) => {
    setSelectedFiles(prev => prev.filter(file => file !== fileToRemove));
  };

  return (
    <>
      <Card style={{ position: 'relative' }}>
        {(stepLoading || attachmentsLoading) && (
          <div style={overlayStyle}>
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <Card.Body>
          <Row className="align-items-center mb-4">
            <Col xs="auto">
              <BackButton />
            </Col>
            <Col>
              <h4 className="mb-0">
                {step.name}
              </h4>
            </Col>
            <Col xs="auto">
              {getStatusIcon(step.status)}
            </Col>
          </Row>

          <Card.Text>
                  <div className="me-2">
                    {formatInstructions(step.instruction)}
                  </div>
              {isCoCStep && (
                <Button 
                  variant="outline-secondary" 
                  size="sm" 
                  className="me-2 mt-2"
                  onClick={() => generateCoC([part], part.purchase_order_id, orderParts)}
                >
                  <FontAwesomeIcon icon={faFileDownload} className="me-1" />
                  Download CoC
                </Button>
              )}
          </Card.Text>

          {step.status !== "in progress" && (
            <>
              <Card.Text>
                <strong>Comment:</strong> {step.comment || "No comment provided"}
              </Card.Text>
              <Card.Text>
                <strong>Updated By:</strong> {step.updated_by || "Unknown"}
              </Card.Text>
              <Card.Text>
                <strong>Updated At:</strong> {formatDateWithTime(step.updated_at)}
              </Card.Text>
            </>
          )}

          {step.status === "in progress" && !showCommentBox && (
            <div className="mb-4">
              <ActionButton 
                variant="success"
                action="approve"
                text="Approve"
              />
              <ActionButton 
                variant="danger"
                action="reject"
                text="Reject"
              />
            </div>
          )}

          {step.status === "approved" && !showCommentBox && (
            <LoadingButton
              variant="warning"
              onClick={handleUnApprove}
              text="Un-Approve"
              loadingText="Un-Approving..."
              className="me-2"
              isLoading={isLoading}
              disabled={isLoading}
            />
          )}

          {step.status === "rejected" && !showCommentBox && (
            <LoadingButton
              variant="warning"
              onClick={handleUnReject}
              text="Un-Reject"
              loadingText="Un-Rejecting..."
              isLoading={isLoading}
              disabled={isLoading}
            />
          )}

          {showCommentBox && (
            <Form className="mb-4">
              <Form.Group controlId="commentBox">
                <Form.Label>
                  <strong>Comment</strong>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={comment}
                  disabled={isLoading}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Form.Group>
              <LoadingButton
                variant="primary"
                onClick={nextStatus == "approve" ? handleApprove : handleReject}
                text="Submit"
                loadingText="Submitting..."
                className="me-2 mt-2"
                isLoading={isLoading}
                disabled={isLoading}
              />
              <LoadingButton
                variant="secondary"
                onClick={handleHideCommentBox}
                text="Cancel"
                loadingText=""
                className="me-2 mt-2"
                isLoading={isLoading}
                disabled={isLoading}
              />
            </Form>
          )}

          <div className="mt-3">
            <Form.Group controlId="fileUpload">
              <Form.Label>
                <strong>Upload Files</strong>
              </Form.Label>
              <Form.Control
                type="file"
                multiple
                onChange={handleUploadFileSelect}
                disabled={uploadLoading}
              />
              <FileAttachments 
                files={selectedFiles}
                onRemove={handleRemoveUploadFile}
                setErrorMessage={setErrorMessage}
              />
              {selectedFiles.length > 0 && (
                <LoadingButton
                  variant="primary"
                  onClick={handleFileUpload}
                  text="Upload Files"
                  loadingText="Uploading..."
                  className="mt-2"
                  isLoading={uploadLoading}
                  disabled={uploadLoading}
                />
              )}
            </Form.Group>
          </div>

          {attachments.length > 0 && (
            <div className="mt-3">
              <h5>Attachments</h5>
              <FileAttachments 
                files={attachments}
                isUploaded={true}
                setErrorMessage={setErrorMessage}
                onFileDeleted={handleFileDeleted}
              />
            </div>
          )}

          {/* Display the error message if there is one */}
          {errorMessage && (
            <Alert variant="danger" className="mt-3">
              {errorMessage}
            </Alert>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default StepDetails;
