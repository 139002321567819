import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import * as XLSX from "xlsx";
import { processNames } from "./Instructions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';

const VALID_FILE_TYPES = {
  'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'xls': 'application/vnd.ms-excel',
  'csv': 'text/csv'
};

const TEMPLATE_HEADERS = [
  'Qty Ordered',
  'Item Name/Description/Vendor Part/Job',
  'Unit Price',
  'Due Date'
];

const parsePart = (part, workflowTemplate) => {
  try {
    if (!part["Qty Ordered"] || !part["Item Name/Description/Vendor Part/Job"] || !part["Unit Price"]) {
      throw new Error("Missing required fields");
    }

    const steps = workflowTemplate.steps.map(templateStep => ({
      index: templateStep.index,
      id: templateStep.id,
      name: templateStep.name,
      status: "in progress",
      instruction_id: null,
      instruction: null
    }));

    return {
      qty_ordered: Number(part["Qty Ordered"].split(" ")[0]),
      part_unique_id: part["Item Name/Description/Vendor Part/Job"].split("\n")[0],
      name: part["Item Name/Description/Vendor Part/Job"].split("\n")[1],
      unit_price: Number(part["Unit Price"]),
      steps: steps,
      due_date: part["Due Date"] ? new Date(part["Due Date"]).toISOString().substring(0, 10) : null,
      workflow_template_id: workflowTemplate.id
    };
  } catch (error) {
    throw new Error(`Failed to parse part: ${error.message}`);
  }
};

const FileUpload = ({ setParts, setEditingIndex, workflowTemplate }) => {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [error, setError] = useState(null);

  const downloadTemplate = () => {
    const ws = XLSX.utils.aoa_to_sheet([TEMPLATE_HEADERS]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Template");
    
    XLSX.utils.sheet_add_aoa(ws, [
      ['10.0 Each', '819-2938-001\nPart Description', '1190.00', '2024-12-31']
    ], { origin: 'A2' });

    // Setting column widths
    ws['!cols'] = [
      { wch: 15 }, // Qty Ordered
      { wch: 40 }, // Item Name/Description
      { wch: 15 }, // Unit Price
      { wch: 15 }  // Due Date
    ];

    XLSX.writeFile(wb, "parts_template.xlsx");
  };

  const onFileSelect = (file) => {
    if (!file) {
      return;
    }

    if (!Object.values(VALID_FILE_TYPES).includes(file.type)) {
      setError("Invalid file type. Please upload an Excel file (.xlsx, .xls) or CSV file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => { 
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array", cellDates: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" });

        const parsedParts = jsonData
          .map((part) => {
            try {
              return parsePart(part, workflowTemplate);
            } catch (error) {
              setError(`Error parsing row: ${error.message}`);
              return null;
            }
          })
          .filter(part => part !== null);

        if (parsedParts.length === 0) {
          setError("No valid parts found in file");
          return;
        }

        setParts(parsedParts);
        setError(null);
      } catch (error) {
        setError(`Failed to read file: ${error.message}`);
      }
    };

    reader.onerror = () => {
      setError("Failed to read file");
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsFileSelected(true);
      onFileSelect(file);
    }
  };

  const clearFile = () => {
    document.getElementById("fileInput").value = "";
    setIsFileSelected(false);
    setParts([]);
    setEditingIndex(null);
    setError(null);
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <Form.Group className="d-flex align-items-center flex-grow-1">
          {isFileSelected && (
            <Button 
              variant="danger" 
              onClick={clearFile} 
              className="me-2"
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
          <Form.Control
            id="fileInput"
            type="file"
            onChange={handleFileChange}
            accept={Object.values(VALID_FILE_TYPES).join(',')}
          />
        </Form.Group>
        <Button 
          variant="outline-secondary" 
          onClick={downloadTemplate}
          className="ms-2"
        >
          <FontAwesomeIcon icon={faDownload} className="me-2" />
          Download Template
        </Button>
      </div>
      
      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}
    </div>
  );
};

export default FileUpload;
