import PropTypes from 'prop-types';
import { useState, useEffect, useMemo, useCallback } from "react";
import { Form, Card } from "react-bootstrap";

function OrderFilter({ orders, onFilter, getOrderStatus }) {
  const [filters, setFilters] = useState({
    searchTerm: "",
    status: "all",
    dateRange: "all",
  });

  const handleSearchChange = useCallback((e) => {
    setFilters(prev => ({ ...prev, searchTerm: e.target.value }));
  }, []);

  const handleStatusChange = useCallback((e) => {
    setFilters(prev => ({ ...prev, status: e.target.value }));
  }, []);

  const handleDateRangeChange = useCallback((e) => {
    setFilters(prev => ({ ...prev, dateRange: e.target.value }));
  }, []);

  const dateUtils = useMemo(() => ({
    isSameDay: (date1, date2) => {
      return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      );
    },
    isThisWeek: (date) => {
      const today = new Date();
      const weekStart = new Date(today.setDate(today.getDate() - today.getDay()));
      const weekEnd = new Date(today.setDate(today.getDate() - today.getDay() + 6));
      return date >= weekStart && date <= weekEnd;
    },
    isSameMonth: (date1, date2) => {
      return (
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      );
    }
  }), []);

  const filteredOrders = useMemo(() => {
    if (!orders) return [];
    
    return orders.filter((order) => {
      const searchMatch =
        !filters.searchTerm ||
        order.customer_name.toLowerCase().includes(filters.searchTerm.toLowerCase()) ||
        order.purchase_order_id.toLowerCase().includes(filters.searchTerm.toLowerCase());

      const orderStatus = getOrderStatus(order.parts);
      const statusMatch =
        filters.status === "all" ||
        (filters.status === "complete" && orderStatus === "Complete") ||
        (filters.status === "rejected" && orderStatus === "Has Rejections") ||
        (filters.status === "not_started" && orderStatus === "Not Started") ||
        (filters.status === "in_progress" && orderStatus === "In Progress");

      const today = new Date();
      const orderDueDate = order.earliest_due_date;
      let dateMatch = true;

      switch (filters.dateRange) {
        case "overdue":
          dateMatch = orderDueDate < today && orderStatus !== "Complete";
          break;
        case "today":
          dateMatch = dateUtils.isSameDay(orderDueDate, today);
          break;
        case "this_week":
          dateMatch = dateUtils.isThisWeek(orderDueDate);
          break;
        case "this_month":
          dateMatch = dateUtils.isSameMonth(orderDueDate, today);
          break;
        default:
          dateMatch = true;
      }

      return searchMatch && statusMatch && dateMatch;
    });
  }, [filters, orders, getOrderStatus, dateUtils]);

  useEffect(() => {
    onFilter(filteredOrders);
  }, [filteredOrders, onFilter]);

  return (
    <Card>
      <Card.Body>
        <Form>
          <h4>Filter Orders</h4>
          <Form.Group className="mb-3">
            <Form.Label>Search</Form.Label>
            <Form.Control
              type="text"
              placeholder="Search by PO # or customer"
              value={filters.searchTerm}
              onChange={handleSearchChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Select
              value={filters.status}
              onChange={handleStatusChange}
            >
              <option value="all">All Status</option>
              <option value="complete">Complete</option>
              <option value="in_progress">In Progress</option>
              <option value="not_started">Not Started</option>
              <option value="rejected">Has Rejections</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Due Date</Form.Label>
            <Form.Select
              value={filters.dateRange}
              onChange={handleDateRangeChange}
            >
              <option value="all">All Dates</option>
              <option value="overdue">Overdue</option>
              <option value="today">Due Today</option>
              <option value="this_week">Due This Week</option>
              <option value="this_month">Due This Month</option>
            </Form.Select>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
}

OrderFilter.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    purchase_order_id: PropTypes.string.isRequired,
    customer_name: PropTypes.string.isRequired,
    parts: PropTypes.array.isRequired,
    earliest_due_date: PropTypes.instanceOf(Date).isRequired,
  })).isRequired,
  onFilter: PropTypes.func.isRequired,
  getOrderStatus: PropTypes.func.isRequired,
};

export default OrderFilter; 