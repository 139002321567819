import { useState, useEffect } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes, faKeyboard } from "@fortawesome/free-solid-svg-icons";

function SearchBar({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showHotkey, setShowHotkey] = useState(true);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        document.getElementById('search-input').focus();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const handleSearch = (value) => {
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <div className="d-flex align-items-center">
      <InputGroup>
        <InputGroup.Text className="bg-white">
          <FontAwesomeIcon icon={faSearch} className="text-secondary" />
        </InputGroup.Text>
        <Form.Control
          id="search-input"
          type="search"
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search by part ID or order number..."
          onFocus={() => setShowHotkey(false)}
          onBlur={() => setShowHotkey(true)}
          className="border-start-0"
        />
        {showHotkey && !searchTerm && (
          <InputGroup.Text className="bg-white text-secondary border-start-0">
            <FontAwesomeIcon icon={faKeyboard} className="me-1" />
            <small>⌘K</small>
          </InputGroup.Text>
        )}
        {searchTerm && (
          <InputGroup.Text 
            className="bg-white border-start-0 cursor-pointer"
            onClick={() => handleSearch('')}
            style={{ cursor: 'pointer' }}
          >
            <FontAwesomeIcon icon={faTimes} className="text-secondary" />
          </InputGroup.Text>
        )}
      </InputGroup>
    </div>
  );
}

export default SearchBar;