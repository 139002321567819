import { ListGroup, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../utils/formatters";

const truncateText = (text, maxLength = 25) => {
  if (!text) return '';
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

function PartsList({ 
  parts, 
  getDueDateColor,
  getPartStatus
}) {
  return (
    <Row className="g-4 mb-5">
      <Col>
        <ListGroup>
          {parts.map((part) => {
            const status = getPartStatus(part);
            const isRejected = status.startsWith('Rejected');
            const isFinished = status === 'Finished';
            
            return (
              <ListGroup.Item
                key={part.id}
                as={Link}
                to={`/part/${part.id}`}
                action
                className="py-3"
              >
                <Row className="g-3">
                  <Col xs={12} lg={8}>
                    <h5 className="mb-2 mb-lg-1">{part.part_unique_id}</h5>
                    <div className="text-muted small d-flex flex-wrap gap-3">
                      <span title={part.name}>
                        <strong>Description:</strong> {truncateText(part.name)}
                      </span>
                      <span>
                        <strong>Order:</strong> {part.purchase_order_id}
                      </span>
                      <span>
                        <strong>Due:</strong> 
                        <span className={getDueDateColor(part.due_date)}>
                          {formatDate(part.due_date)}
                        </span>
                      </span>
                      <span>
                        <strong>Qty:</strong> {part.qty_ordered}
                      </span>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className="align-items-center d-flex">
                    <div className={`text-${isFinished ? 'end' : 'start'} text-lg-end w-100`}>
                      {isFinished ? (
                        <div className="text-success">
                          <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                          Complete
                        </div>
                      ) : isRejected ? (
                        <div className="text-danger">
                          <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                          {status}
                        </div>
                      ) : (
                        <div className="text-secondary">
                          <div className="mb-1">In Progress: {status}</div>
                          <div className="progress" style={{ height: '4px' }}>
                            <div 
                              className="progress-bar" 
                              style={{ 
                                width: `${((part.steps.findIndex(s => s.status === "in progress") + 1) / part.steps.length) * 100}%` 
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Col>
    </Row>
  );
}

export default PartsList;