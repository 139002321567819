import { useState, useEffect } from 'react';
import { Card, ListGroup, Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from './axios';
import LoadingButton from './Buttons/LoadingButton';

function DepartmentManager({ employees, setError, setSuccessMessage }) {
  const [departments, setDepartments] = useState([]);
  const [editingDepartment, setEditingDepartment] = useState(null);
  const [availableSteps, setAvailableSteps] = useState([]);
  const [editingUsers, setEditingUsers] = useState([]);
  const [editingSteps, setEditingSteps] = useState([]);
  const [showNewDepartmentModal, setShowNewDepartmentModal] = useState(false);
  const [newDepartmentName, setNewDepartmentName] = useState('');
  const [showDeleteDepartmentModal, setShowDeleteDepartmentModal] = useState(false);
  const [departmentNameEdit, setDepartmentNameEdit] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    fetchDepartments();
    fetchAvailableSteps();
  }, [employees]);

  const fetchDepartments = async () => {
    try {
      const response = await axiosInstance.get('/departments');
      setDepartments(response.data);
    } catch (error) {
      setError(error.response?.data || 'Failed to fetch departments');
    }
  };

  const fetchAvailableSteps = async () => {
    try {
      const response = await axiosInstance.get('/steps');
      setAvailableSteps(response.data);
    } catch (error) {
      setError(error.response?.data || 'Failed to fetch available steps');
    }
  };

  const handleSaveDepartment = async () => {
    setIsEditing(true);
    try {
      await handleUpdateDepartmentUsers(editingDepartment.id, editingUsers);
      await handleUpdateDepartmentSteps(editingDepartment.id, editingSteps);
      
      setSuccessMessage('Department updated successfully');
      setEditingDepartment(null);
      fetchDepartments();
    } catch (error) {
      setError(error.response?.data || 'Failed to update department');
    } finally {
      setIsEditing(false);
    }
  };

  const handleCreateDepartment = async () => {
    try {
      await axiosInstance.post('/departments', { name: newDepartmentName });
      setSuccessMessage('Department created successfully');
      setShowNewDepartmentModal(false);
      setNewDepartmentName('');
      fetchDepartments();
    } catch (error) {
      setError(error.response?.data || 'Failed to create department');
    }
  };

  const handleDeleteDepartment = async () => {
    setIsDeleting(true);
    try {
      await axiosInstance.delete(`/departments/${editingDepartment.id}`);
      setSuccessMessage('Department deleted successfully');
      setShowDeleteDepartmentModal(false);
      setEditingDepartment(null);
      fetchDepartments();
    } catch (error) {
      setError(error.response?.data || 'Failed to delete department');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleUpdateDepartmentUsers = async (departmentId, users) => {
    try {
      await axiosInstance.put(`/departments/${departmentId}/users`, { users });
      setSuccessMessage('Department users updated successfully');
      fetchDepartments();
    } catch (error) {
      setError(error.response?.data || 'Failed to update department users');
    }
  };

  const handleUpdateDepartmentSteps = async (departmentId, steps) => {
    try {
      await axiosInstance.put(`/departments/${departmentId}/steps`, { steps });
      setSuccessMessage('Department steps updated successfully');
      fetchDepartments();
    } catch (error) {
      setError(error.response?.data || 'Failed to update department steps');
    }
  };

  const handleUpdateDepartmentName = async () => {
    try {
      await axiosInstance.put(`/departments/${editingDepartment.id}/name`, {
        name: departmentNameEdit
      });
      setSuccessMessage('Department name updated successfully');
      fetchDepartments();
    } catch (error) {
      setError(error.response?.data || 'Failed to update department name');
    }
  };

  return (
    <>
      <Card className="mt-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3>Departments</h3>
            <Button
              variant="primary"
              size="sm"
              onClick={() => setShowNewDepartmentModal(true)}
            >
              Add Department
            </Button>
          </div>
          <ListGroup>
            {departments.map(department => (
              <ListGroup.Item key={department.id}>
                <div className="d-flex justify-content-between align-items-center">
                  {editingDepartment?.id === department.id && editingDepartment?.isEditingName ? (
                    <Form.Control
                      type="text"
                      value={departmentNameEdit}
                      onChange={(e) => setDepartmentNameEdit(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleUpdateDepartmentName();
                          setEditingDepartment({ ...editingDepartment, isEditingName: false });
                        }
                      }}
                      onBlur={() => {
                        handleUpdateDepartmentName();
                        setEditingDepartment({ ...editingDepartment, isEditingName: false });
                      }}
                      autoFocus
                    />
                  ) : (
                    <h5 className="mb-0">{department.name}</h5>
                  )}
                  <div className="d-flex gap-2">
                    <Button
                      variant="link"
                      size="sm"
                      onClick={() => {
                        setEditingDepartment(department);
                        setDepartmentNameEdit(department.name);
                        setEditingUsers(department.users.map(u => u.id));
                        setEditingSteps(department.steps.map(s => s.id));
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button
                      variant="link"
                      size="sm"
                      className="text-danger"
                      onClick={() => {
                        setEditingDepartment({ id: department.id, name: department.name });
                        setShowDeleteDepartmentModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                </div>
                <div className="mt-2">
                  <small className="text-muted">
                    Users: {department.users?.length || 0} | 
                    Steps: {department.steps?.length || 0}
                  </small>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>

      {/* Edit Modal */}
      <Modal
        show={!!editingDepartment && !editingDepartment.isEditingName && !showDeleteDepartmentModal}
        onHide={() => setEditingDepartment(null)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-4">
            <Form.Label><strong>Department Name</strong></Form.Label>
            <Form.Control
              type="text"
              value={departmentNameEdit}
              onChange={(e) => setDepartmentNameEdit(e.target.value)}
              placeholder="Enter department name"
            />
          </Form.Group>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label><strong>Users</strong></Form.Label>
                <ListGroup style={{ maxHeight: '300px', overflowY: 'auto' }}>
                  {employees.map(emp => (
                    <ListGroup.Item 
                      key={emp.id}
                      className="d-flex align-items-center"
                    >
                      <Form.Check
                        type="checkbox"
                        id={`user-${emp.id}`}
                        checked={editingUsers.includes(emp.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setEditingUsers([...editingUsers, emp.id]);
                          } else {
                            setEditingUsers(editingUsers.filter(id => id !== emp.id));
                          }
                        }}
                        label={<span className="ms-2">{emp.username}</span>}
                      />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label><strong>Steps</strong></Form.Label>
                <ListGroup style={{ maxHeight: '300px', overflowY: 'auto' }}>
                  {availableSteps.map(step => (
                    <ListGroup.Item 
                      key={step.id}
                      className="d-flex align-items-center"
                    >
                      <Form.Check
                        type="checkbox"
                        id={`step-${step.id}`}
                        checked={editingSteps.includes(step.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setEditingSteps([...editingSteps, step.id]);
                          } else {
                            setEditingSteps(editingSteps.filter(id => id !== step.id));
                          }
                        }}
                        label={<span className="ms-2">{step.name}</span>}
                      />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditingDepartment(null)}>
            Cancel
          </Button>
          <LoadingButton
            variant="primary"
            onClick={async () => {
              try {
                setIsEditing(true);
                if (departmentNameEdit !== editingDepartment.name) {
                  await handleUpdateDepartmentName();
                }
                await handleSaveDepartment();
              } catch (error) {
                setError('Failed to update department');
              }
            }}
            isLoading={isEditing}
            text="Save Changes"
            loadingText="Saving..."
          />
        </Modal.Footer>
      </Modal>

      {/* New Department Modal */}
      <Modal
        show={showNewDepartmentModal}
        onHide={() => setShowNewDepartmentModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Department Name</Form.Label>
            <Form.Control
              type="text"
              value={newDepartmentName}
              onChange={(e) => setNewDepartmentName(e.target.value)}
              placeholder="Enter department name"
              autoFocus
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNewDepartmentModal(false)}>
            Cancel
          </Button>
          <LoadingButton
            variant="primary"
            onClick={handleCreateDepartment}
            isLoading={isEditing}
            text="Create"
            loadingText="Creating..."
            disabled={!newDepartmentName.trim()}
          />
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={showDeleteDepartmentModal}
        onHide={() => {
          setShowDeleteDepartmentModal(false);
          setEditingDepartment(null);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the department "{editingDepartment?.name}"? 
          This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={() => {
              setShowDeleteDepartmentModal(false);
              setEditingDepartment(null);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="danger"
            onClick={handleDeleteDepartment}
            isLoading={isDeleting}
            text="Delete"
            loadingText="Deleting..."
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DepartmentManager; 