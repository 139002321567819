export const AUTH_CONFIG = {
    COOKIE_OPTIONS: {
        jwt: {
            expires: 1 / 96,
            sameSite: "Strict",
            secure: process.env.NODE_ENV === 'prod'
        },
        refreshToken: {
            expires: 7,
            sameSite: "Strict",
            secure: process.env.NODE_ENV === 'prod'
        }
    }
}; 