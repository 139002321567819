import { useState, useEffect, useContext } from 'react';
import { Card, Button, Alert, Modal, Form, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash, faCheck, faTimes, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from './axios';
import LoadingButton from './Buttons/LoadingButton';
import { AuthContext } from './Context/AuthContext';

const ROLE_HIERARCHY = {
  owner: 3,
  admin: 2,
  user: 1
};

export default function UserManager({ setError, setSuccessMessage, onEmployeesUpdate }) {
    const { user, username, role, userId } = useContext(AuthContext);
    const [employees, setEmployees] = useState([]);
    const [pendingUsers, setPendingUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [inviteLink, setInviteLink] = useState('');
    const [copySuccess, setCopySuccess] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [isGeneratingLink, setIsGeneratingLink] = useState(false);
  
  
    const hasPermission = (requiredRole) => {
      return (ROLE_HIERARCHY[role] || 0) >= (ROLE_HIERARCHY[requiredRole] || 0);
    };
  
    const fetchUsers = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.get('/users/all');
        setEmployees(response.data.active);
        setPendingUsers(response.data.pending);
        onEmployeesUpdate(response.data.active);
      } catch (error) {
        console.error('Error fetching users:', error);
        setError(error.response?.data || 'Failed to fetch users');
      } finally {
        setIsLoading(false);
      }
    };
  
    useEffect(() => {
      if (hasPermission('admin')) {
        fetchUsers();
      }
    }, [role]);
  
    const handleRemoveEmployee = async () => {
      if (!selectedEmployee || selectedEmployee.role === 'owner' || selectedEmployee.id === user?.id) {
        setError('Cannot remove this user');
        return;
      }
  
      setRemoveLoading(true);
      try {
        await axiosInstance.delete(`/users/${selectedEmployee.id}/remove-from-company`);
        setSuccessMessage('Employee removed successfully');
        fetchUsers();
        setEditingId(null);
        setShowRemoveModal(false);
        setSelectedEmployee(null);
      } catch (error) {
        setError(error.response?.data || 'Failed to remove employee');
      } finally {
        setRemoveLoading(false);
      }
    };
  
    const handleApproveUser = async (userId) => {
      try {
        await axiosInstance.post(`/users/${userId}/approve`);
        setSuccessMessage('User approved successfully');
        fetchUsers();
        setTimeout(() => setSuccessMessage(null), 3000);
      } catch (error) {
        setError(error.response?.data || 'Failed to approve user');
      }
    };
  
    const generateInviteLink = async () => {
      setIsGeneratingLink(true);
      try {
        const response = await axiosInstance.post('/company/invite-link');
        setInviteLink(response.data.inviteLink);
      } catch (error) {
        setError(error.response?.data || 'Failed to generate invite link');
      } finally {
        setIsGeneratingLink(false);
      }
    };
  
    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(inviteLink);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      } catch (err) {
        setError('Failed to copy to clipboard');
      }
    };
  
    const handleRoleChange = async (employee, newRole) => {
      if (employee.role === 'owner' || employee.id === user?.id) {
        setError('Cannot modify this user\'s role');
        return;
      }
  
      try {
        await axiosInstance.post(`/users/${employee.id}/role`, { role: newRole });
        setSuccessMessage(`User role updated to ${newRole}`);
        fetchUsers();
        setEditingId(null);
        setTimeout(() => setSuccessMessage(null), 3000);
      } catch (error) {
        setError(error.response?.data || 'Failed to update user role');
      }
    };
  
    const handleRejectUser = async (userId) => {
      try {
        await axiosInstance.delete(`/users/${userId}/reject`);
        setSuccessMessage('User rejected and removed');
        fetchUsers();
        setTimeout(() => setSuccessMessage(null), 3000);
      } catch (error) {
        setError(error.response?.data || 'Failed to reject user');
      }
    };
  
    const handleEditClick = (employeeId) => {
      setEditingId(employeeId);
    };
  
    const handleCancelEdit = () => {
      setEditingId(null);
    };
  
    const renderEmployeeItem = (employee) => {
      const isEditing = editingId === employee.id;
      const isOwner = employee.role === 'owner';
      const isSelf = String(employee.id) === String(user?.id);
      const canEdit = hasPermission('owner') && !isSelf && !isOwner;
  
      const getRoleBadgeClass = (employeeRole) => {
        switch(employeeRole) {
          case 'owner': return 'bg-dark';
          case 'admin': return 'bg-primary';
          default: return 'bg-secondary';
        }
      };
  
      return (
        <ListGroup.Item 
          key={employee.id}
          className={`d-flex align-items-center justify-content-between ${isEditing ? 'bg-light' : ''}`}
        >
          <div className="d-flex align-items-center" style={{ width: '300px' }}>
            <div className="d-flex align-items-center gap-2" style={{ width: '80px' }}>
              <span className={`badge ${getRoleBadgeClass(employee.role)}`} style={{ width: '60px' }}>
                {employee.role}
              </span>
            </div>
            <span style={{ width: '120px' }}>{employee.username}</span>
          </div>
          
          <div className="d-flex align-items-center gap-2">
            {isEditing ? (
              canEdit && (
                <>
                  <Button
                    variant={employee.role === 'admin' ? 'warning' : 'success'}
                    size="sm"
                    onClick={() => handleRoleChange(employee, employee.role === 'admin' ? 'user' : 'admin')}
                  >
                    <FontAwesomeIcon 
                      icon={employee.role === 'admin' ? faArrowDown : faArrowUp} 
                    />
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      setSelectedEmployee(employee);
                      setShowRemoveModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={handleCancelEdit}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </>
              )
            ) : (
              canEdit && (
                <Button
                  variant="link"
                  className="p-0"
                  onClick={() => handleEditClick(employee.id)}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
              )
            )}
          </div>
        </ListGroup.Item>
      );
    };
  
    const getSortedEmployees = () => {
      return [...employees].sort((a, b) => {
        // Self always comes first
        if (String(a.id) === String(userId)) return -1;
        if (String(b.id) === String(userId)) return 1;
        
        // Then sort by role hierarchy
        const roleA = ROLE_HIERARCHY[a.role] || 0;
        const roleB = ROLE_HIERARCHY[b.role] || 0;
        if (roleA !== roleB) return roleB - roleA;
        
        // Finally sort by username
        return a.username.localeCompare(b.username);
      });
    };

    useEffect(() => {
      return () => {
        // Clear any pending timeouts when component unmounts
        setSuccessMessage(null);
        setError(null);
      };
    }, []);

    return (
    <>
        <Card>
            <Card.Body>
            <h3>Pending Users</h3>
            
            <div className="mb-4">
                <div className="d-flex gap-2 align-items-center">
                {inviteLink ? (
                    <>
                    <Form.Control
                        type="text"
                        value={inviteLink}
                        readOnly
                        className="flex-grow-1"
                    />
                    <Button
                        variant={copySuccess ? "success" : "primary"}
                        onClick={copyToClipboard}
                        className="flex-shrink-0"
                    >
                        {copySuccess ? "Copied!" : "Copy"}
                    </Button>
                    <Button
                        variant="outline-secondary"
                        onClick={() => setInviteLink('')}
                        className="flex-shrink-0"
                    >
                        Clear
                    </Button>
                    </>
                ) : (
                    <Button 
                    variant="primary" 
                    onClick={generateInviteLink}
                    disabled={isGeneratingLink}
                    >
                    {isGeneratingLink ? "Generating..." : "Generate Invite Link"}
                    </Button>
                )}
                </div>
            </div>

            <ListGroup>
                {pendingUsers.length === 0 ? (
                <ListGroup.Item className="text-center text-muted">
                    No pending users
                </ListGroup.Item>
                ) : (
                pendingUsers.map((user) => (
                    <ListGroup.Item 
                    key={user.id}
                    className="d-flex align-items-center justify-content-between"
                    >
                    <div className="d-flex align-items-center" style={{ width: '200px' }}>
                        <span className="me-2">{user.username}</span>
                        <small className="text-muted">
                        {new Date(user.created_at).toLocaleDateString()}
                        </small>
                    </div>
                    <div className="d-flex gap-2">
                        <Button
                        variant="success"
                        size="sm"
                        onClick={() => handleApproveUser(user.id)}
                        >
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                        <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleRejectUser(user.id)}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </div>
                    </ListGroup.Item>
                ))
                )}
            </ListGroup>
            </Card.Body>
        </Card>

        <Card className="mt-4">
            <Card.Body>
            <h3>Registered Users</h3>
            {isLoading ? (
                <div className="text-center py-3">Loading...</div>
            ) : (
                <ListGroup>
                {employees.length === 0 ? (
                    <ListGroup.Item className="text-center text-muted">
                    No registered users found
                    </ListGroup.Item>
                ) : (
                    getSortedEmployees().map(employee => renderEmployeeItem(employee))
                )}
                </ListGroup>
            )}
            </Card.Body>
        </Card>

        {/* Delete Confirmation Modal */}
        <Modal 
          show={showRemoveModal} 
          onHide={() => {
            setShowRemoveModal(false);
            setSelectedEmployee(null);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Remove Employee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove <strong>{selectedEmployee?.username}</strong> from your company? 
            This action cannot be undone.
          </Modal.Body>
          <Modal.Footer>
            <Button 
              variant="secondary" 
              onClick={() => {
                setShowRemoveModal(false);
                setSelectedEmployee(null);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="danger"
              onClick={handleRemoveEmployee}
              text="Remove Employee"
              loadingText="Removing..."
              isLoading={removeLoading}
            />
          </Modal.Footer>
        </Modal>
    </>
  );
} 