import React, { useState, useContext } from "react";
import { Form, Container, Row, Col, Card, Alert } from "react-bootstrap";
import axiosInstance from "./axios";
import { AuthContext } from "./Context/AuthContext";
import Cookies from "js-cookie";
import LoadingButton from "./Buttons/LoadingButton";
import { Link, useNavigate } from "react-router-dom";
import { AUTH_CONFIG } from './config/auth';

const LoginPage = () => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { login, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const { data } = await axiosInstance.post("/login", formData);
      
      if (!data.accessToken || !data.refreshToken) {
        throw new Error('Invalid login response: Missing tokens');
      }

      const tokenPromises = [
        Cookies.set("jwt", data.accessToken, AUTH_CONFIG.COOKIE_OPTIONS.jwt),
        Cookies.set("refreshToken", data.refreshToken, AUTH_CONFIG.COOKIE_OPTIONS.refreshToken)
      ];

      await Promise.all(tokenPromises);

      const jwt = Cookies.get('jwt');
      const refreshToken = Cookies.get('refreshToken');

      if (!jwt || !refreshToken) {
        throw new Error('Failed to set authentication tokens');
      }

      login(data);
      navigate("/");
    } catch (err) {
      Cookies.remove("jwt");
      Cookies.remove("refreshToken");
      console.error('Login Error:', {
        status: err.response?.status,
        statusText: err.response?.statusText,
        data: err.response?.data,
        error: err.message
      });

      const errorMessage = err.response?.data || 
        err.response?.statusText || 
        err.message || 
        "Login failed - please try again later";
      
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  if (isAuthenticated) {
    navigate("/");
    return null;
  }

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col xs={12} md={6}>
          <Card>
            <Card.Body>
              <h2 className="text-center">Login</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    name="username"
                    type="text"
                    value={formData.username}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <LoadingButton
                  type="submit"
                  variant="primary"
                  text="Login"
                  loadingText="Logging in..."
                  className="w-100 mb-3"
                  isLoading={isLoading}
                />

                <div className="text-center">
                  <small>
                    Don't have an account? <Link to="/register">Register here</Link>
                  </small>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
